@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #f9fafb;
    --foreground: #121212;

    --muted: #f1f5f9;
    --muted-foreground: #393939;

    --popover: #f9fafb;
    --popover-foreground: #121212;

    --card: #f9fafb;
    --card-foreground: #121212;

    --border: #c6c6c6;
    --input: #c6c6c6;

    --primary: #ff5b24;
    --primary-foreground: #f9fafb;

    --secondary: #49367e;
    --secondary-foreground: #f9fafb;

    --accent: #f1f5f9;
    --accent-foreground: #212121;

    --success: #48bb78;
    --success-foreground: #f9fafb;

    --destructive: #e53e3e;
    --destructive-foreground: #f9fafb;

    --warning: #f6ad55;
    --warning-foreground: #121212;

    --info: #3182ce;
    --info-foreground: #f9fafb;

    --ring: #ff5b24;

    --radius: 0.5rem;
  }
  .dark {
    --background: #121212;
    --foreground: #f9fafb;

    --muted: #393939;
    --muted-foreground: #f1f5f9;

    --popover: #121212;
    --popover-foreground: #f9fafb;

    --card: #121212;
    --card-foreground: #f9fafb;

    --border: #393939;
    --input: #393939;

    --primary: #ff5b24;
    --primary-foreground: #f9fafb;

    --secondary: #49367e;
    --secondary-foreground: #f9fafb;

    --accent: #212121;
    --accent-foreground: #f1f5f9;

    --success: #48bb78;
    --success-foreground: #121212;

    --destructive: #e53e3e;
    --destructive-foreground: #121212;

    --warning: #f6ad55;
    --warning-foreground: #121212;

    --info: #3182ce;
    --info-foreground: #121212;

    --ring: #ff5b24;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
